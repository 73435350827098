import React, { useEffect, useRef, useState } from "react";

function VrtSessionDetail({ patientId, agora }) {
  const videoRef = useRef(null);
  const [agoraClient, setAgoraClient] = useState(null);

  useEffect(() => {
    if (!agora || agora.length === 0) {
      console.error("Missing Agora configuration.");
      return;
    }

    // Usa il primo elemento dell'array `agora`
    const { channel_name, token, appid } = agora[0];

    const initAgora = async () => {
      try {
        const AgoraRTC = window.AgoraRTC; // La SDK Agora è caricata come asset statico

        // Initialize Agora Client
        const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        setAgoraClient(client);

        const myid = 1234; // Identificatore unico per l'utente

        // Set audience role
        client.setClientRole("audience");

        // Join channel
        await client.join(appid, channel_name, token, myid);

        console.log("User joined channel as an audience.");

        // Subscribe to user-published event
        client.on("user-published", async (user, mediaType) => {
          console.log("User published:", user.uid);

          // Subscribe to the user stream
          await client.subscribe(user, mediaType);
          console.log("Subscribed to user:", user.uid);

          if (mediaType === "video") {
            const videoTrack = user.videoTrack;
            if (videoTrack && videoRef.current) {
              videoTrack.play(videoRef.current);
            }
          }
        });

        // Handle user-unpublished event
        client.on("user-unpublished", (user) => {
          console.log("User unpublished:", user.uid);
          if (videoRef.current) {
            videoRef.current.innerHTML = ""; // Clear video content
          }
        });

        // Handle peer-leave event
        client.on("user-left", (user) => {
          console.log("User left:", user.uid);
          if (videoRef.current) {
            videoRef.current.innerHTML = ""; // Clear video content
          }
        });
      } catch (error) {
        console.error("Error initializing AgoraRTC:", error);
      }
    };

    initAgora();

    return () => {
      if (agoraClient) {
        agoraClient.leave().then(() => {
          console.log("Left the Agora channel.");
        }).catch((err) => console.error("Error leaving Agora channel:", err));
      }
    };
  }, [agora, agoraClient]);

  return (
    <div>
      <h2>Dettaglio Sessione</h2>
      <p><strong>ID Paziente:</strong> {patientId}</p>
      <div
        id="video-container"
        ref={videoRef}
        style={{
          width: "100%",
          height: "500px",
          backgroundColor: "#000",
        }}
      />
    </div>
  );
}

export default VrtSessionDetail;
